$(function() {
  $.get("https://www.elfinanciero.com.mx/economia", function(e) {
    var t = $(e).find(".most-read > ol");
    t.find("img").each(function(e, t) {
      var n = $(t).attr("src");
      $(t).attr("src", "https://www.elfinanciero.com.mx" + n), $(t).addClass("img-fluid")
    }), t.find("a").each(function(e, t) {
      var n = $(t).attr("href");
      $(t).attr("href", "https://www.elfinanciero.com.mx" + n), $(t).attr("target", "_blank")
    }), t.find("li").addClass("item"), $(".MultiCarousel-inner").append(t.html()), n()
  })

  $.get("https://braavos.elfinanciero.com.mx/table/MXN:CUR,MEXBOL:IND,EURMXN:CUR,FBMEX:IND,CRAMMMIX:IND/divisas", function(json) {
    var rows = json.table.rows
    $.each(rows, function (index, row) {
      var name = row._SECURITY_.value
      if (name == 'Forex') {
        name = 'Dólar'
      }

      var link = row._LINK_.value
      var value = row.PX_LAST.value
      var difference = row.CHG_PCT_1D.value
      var diffClassName = row.CHG_PCT_1D.className

      var $html = $("<div>", { class: 'column col-lg col-md-4 col-6' })
      var $link = $("<a>", { href: "https://www.elfinanciero.com.mx" + link, target: "_blank" })
      var $name = $("<p>", { class: 'name'}).text(name)
      var $values = $("<p>", { class: 'values'})
      $values.append($("<span>").text(value))
      $values.append($("<span>", { class: diffClassName }).text(difference + " %"))

      $link.append($name)
      $link.append($values)
      $html.append($link)

      $('.financiero-charts .row').append($html)
    })
    // var $html = $(html).find(".is-markets");
    // $html.find(".columns").addClass("row")
    // $html.find(".column").addClass("col-lg-2 col-md-4 col-6")

    // $html.find("a").each(function(e, t) {
    //   var n = $(t).attr("href");
    //   $(t).attr("href", "https://www.elfinanciero.com.mx" + n), $(t).attr("target", "_blank")
    // })

    // $(".financiero-charts").append($html.html())
  });

  var e = ".MultiCarousel",
  u = ".MultiCarousel-inner",
  c = "";

  function n() {
    var t = 0,
    n = 0,
    i = "",
    r = "",
    o = $(e).width(),
    s = $("body").width();
    $(u).each(function() {
      n += 1;
      var e = $(this).find(".item").length;
      i = $(this).parent().attr("data-items"), r = i.split(","), $(this).parent().attr("id", "MultiCarousel" + n), c = (t = 1200 <= s ? r[3] : 992 <= s ? r[2] : 768 <= s ? r[1] : r[0], o / t), $(this).css({
        transform: "translateX(0px)",
        width: c * e
      }), $(this).find(".item").each(function() {
        $(this).outerWidth(c)
      }), $(".leftLst").addClass("over"), $(".rightLst").removeClass("over")
    })
  }

  function t(e, t) {
    var n = "#" + $(t).parent().attr("id");
    ! function(e, t, n) {
      var i = ".leftLst",
      r = ".rightLst",
      o = "",
      s = $(t + " " + u).css("transform").match(/-?[\d\.]+/g),
      a = Math.abs(s[4]);
      if (0 == e) o = parseInt(a) - parseInt(c * n), $(t + " " + r).removeClass("over"), o <= c / 2 && (o = 0, $(t + " " + i).addClass("over"));
      else if (1 == e) {
        var l = $(t).find(u).width() - $(t).width();
        o = parseInt(a) + parseInt(c * n), $(t + " " + i).removeClass("over"), l - c / 2 <= o && (o = l, $(t + " " + r).addClass("over"))
      }
      $(t + " " + u).css("transform", "translateX(" + -o + "px)")
    }(e, n, $(n).attr("data-slide"))
  }
  $(".leftLst, .rightLst").click(function() {
    t($(this).hasClass("leftLst") ? 0 : 1, this)
  }), $(window).resize(function() {
    n()
  })
});
